<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
            <div class="row">
                <div class="col-md-12 d-flex align-items-stretch grid-margin">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin">
                            <div class="card">
                                <div class="card-header">
                                     <h4 v-if="!this.form.id" class="card-title">{{ $t('add-spotter') }}</h4>
                                     <h4 v-if="this.form.id" class="card-title">{{ $t('edit-spotter') }}</h4>
                                </div>
                                <div class="card-body new-card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <b-form-group :label="$t('username*')" label-for="user_name">
                                                        <b-form-input
                                                                type="text"
                                                                id="user_name"
                                                                name="user_name"
                                                                autocomplete="new-password"
                                                                :placeholder="$t('username*')"
                                                                v-model="$v.form.user_name.$model"
                                                                :state="$v.form.user_name.$dirty ? !$v.form.user_name.$error : null"
                                                                aria-describedby="input-1-live-feedback"
                                                        >
                                                        </b-form-input>
                                                        <b-form-invalid-feedback id="input-1-live-feedback">{{ $t("field-required") }}
                                                        </b-form-invalid-feedback>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-6">
                                                    <b-form-group :label="$t('spotter-name*')" label-for="name">
                                                        <b-form-input
                                                                type="text"
                                                                id="name"
                                                                name="name"
                                                                autocomplete="new-password"
                                                                :placeholder="$t('spotter-name-placeholder')"
                                                                v-model="$v.form.name.$model"
                                                                :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                                aria-describedby="input-1-live-feedback"
                                                        >
                                                        </b-form-input>
                                                        <b-form-invalid-feedback id="input-1-live-feedback">{{ $t("field-required") }}
                                                        </b-form-invalid-feedback>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label class="mr-sm-2 ml-0 pl-0"
                                                           for="inline-form-custom-select-pref">{{ $t('approve-spots') }}</label><br>
                                                    <toggle-button v-model="form.auto_approve" :sync="true"
                                                                   class="switchColor"/>
                                                </div>
                                                <div class="col-md-4">
                                                    <label class="mr-sm-2 ml-0 pl-0"
                                                           for="inline-form-custom-select-pref">{{ $t('suspend') }}</label><br>
                                                    <toggle-button v-model="form.status" :sync="true"
                                                                   class="switchColor"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('email*')" label-for="email">
                                                <b-form-input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        autocomplete="new-password"
                                                        :placeholder="$t('email-placeholder')"
                                                        v-model="$v.form.email.$model"
                                                        :state="$v.form.email.$dirty ? !$v.form.email.$error : null"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="!$v.form.email.required"
                                                                         id="input-1-live-feedback">{{ $t("field-required") }}
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback v-if="!$v.form.email.email"
                                                                         id="input-1-live-feedback">{{ $t("Enter a valid Email.") }}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <b-form-group :label="$t('completed-training')" label-for="approval_date">
                                                        <b-form-select v-model="form.training_status"
                                                                       :options="training_status"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-6">
                                                    <b-form-group :label="$t('training-date')" label-for="approval_date">
                                                        <b-form-input
                                                                type="date"
                                                                id="approval_date"
                                                                name="approval_date"
                                                                autocomplete="new-password"
                                                                placeholder="Approval Date"
                                                                v-model="form.date_completed"
                                                        >
                                                        </b-form-input>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                                <b-form-checkbox v-if="this.form.id"
                                                    id="input-group-2"
                                                    label-for="input-2" 
                                                    v-model="checkedPassword">
                                                   {{ $t('check-to-update-password(password-will-be-updated-for-spotterchat-as-well)') }}
                                                </b-form-checkbox>
                                                <br />
                                    <div class="row">
                                        <div class="col-md-6" v-if="this.checkedPassword">
                                            <b-form-group
                                                    id="input-group-2"
                                                    :label="$t('password')"
                                                    label-for="input-2"
                                            >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-2"
                                                autocomplete="new-password"
                                                :type="inputType"
                                                :placeholder="$t('password')"
                                                v-model="$v.form.password.$model"
                                                :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                                                aria-describedby="input-3-live-feedback"
                                        ></b-form-input>
                                         <b-form-invalid-feedback v-if="!$v.form.password.required"
                                                                  id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="!$v.form.password.minLength"
                                                                  id="input-3-live-feedback">Password must have at least {{$v.form.password.$params.minLength.min }} letters.</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="form.password !== '' && !$v.form.password.valid && $v.form.password.minLength" 
                                            id="input-3-live-feedback">{{ $t("Password contains atleast One Uppercase, One Lowercase, One Number and One Special Character.") }}</b-form-invalid-feedback>
                                      <i @click="toggleShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword, 'fa fa-eye-slash': !showPassword, }"></i></span>

                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6" v-if="this.checkedPassword">
                                            <b-form-group
                                                    id="input-group-4"
                                                    :label="$t('confirm-password')"
                                                    label-for="input-4"
                                            >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-4"
                                                autocomplete="new-password"
                                                :type="inputType1"
                                                :placeholder="$t('confirm-password')"
                                                v-model="$v.form.password_confirmation.$model"
                                                :state="$v.form.password_confirmation.$dirty ? !$v.form.password_confirmation.$error : null"
                                                aria-describedby="input-4-live-feedback"
                                        ></b-form-input>
                                              <b-form-invalid-feedback v-if="!$v.form.password_confirmation.required"
                                                                       id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="!$v.form.password_confirmation.sameAsPassword"
                                                                  id="input-4-live-feedback">Passwords must be identical.</b-form-invalid-feedback>
                                      <i @click="toggleConfirmShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword1, 'fa fa-eye-slash': !showPassword1, }"></i></span>

                                            </b-form-group>
                                         </div>
                                        </div>
                                        <div class="row" v-if="!this.form.id">
                                        <div class="col-md-6">
                                            <b-form-group
                                                    id="input-group-2"
                                                    :label="$t('password')"
                                                    label-for="input-2"
                                            >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-2"
                                                autocomplete="new-password"
                                                :type="inputType"
                                                :placeholder="$t('password')"
                                                v-model="$v.form.password.$model"
                                                :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
                                                aria-describedby="input-3-live-feedback"
                                        ></b-form-input>
                                         <b-form-invalid-feedback v-if="!$v.form.password.required"
                                                                  id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="!$v.form.password.minLength"
                                                                  id="input-3-live-feedback">Password must have at least {{$v.form.password.$params.minLength.min }} letters.</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="form.password !== '' && !$v.form.password.valid2 && $v.form.password.minLength" 
                                            id="input-3-live-feedback">{{ $t("Password contains atleast One Uppercase, One Lowercase, One Number and One Special Character.") }}</b-form-invalid-feedback>
                                      <i @click="toggleShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword, 'fa fa-eye-slash': !showPassword, }"></i></span>

                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group
                                                    id="input-group-4"
                                                    :label="$t('confirm-password')"
                                                    label-for="input-4"
                                            >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-4"
                                                autocomplete="new-password"
                                                :type="inputType1"
                                                :placeholder="$t('confirm-password')"
                                                v-model="$v.form.password_confirmation.$model"
                                                :state="$v.form.password_confirmation.$dirty ? !$v.form.password_confirmation.$error : null"
                                                aria-describedby="input-4-live-feedback"
                                        ></b-form-input>
                                              <b-form-invalid-feedback v-if="!$v.form.password_confirmation.required"
                                                                       id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="!$v.form.password_confirmation.sameAsPassword"
                                                                  id="input-4-live-feedback">Passwords must be identical.</b-form-invalid-feedback>
                                      <i @click="toggleConfirmShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword1, 'fa fa-eye-slash': !showPassword1, }"></i></span>

                                            </b-form-group>
                                    </div>
                                </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('phone')" label-for="mobile_phone">
                                                <b-form-input
                                                        type="text"
                                                        id="mobile_phone"
                                                        name="mobile_phone"
                                                        autocomplete="new-password"
                                                        :placeholder="$t('phone')"
                                                        v-model="form.mobile_phone"
                                                >
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('date-of-birth')" label-for="dob">
                                                <b-form-input
                                                        type="date"
                                                        id="dob"
                                                        name="dob"
                                                        autocomplete="new-password"
                                                        :placeholder="$t('date-of-birth')"
                                                        v-model="form.dob"
                                                >
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group :label="$t('location')" label-for="location">
                                                <b-form-input
                                                        type="text"
                                                        id="location"
                                                        name="location"
                                                        autocomplete="new-password"
                                                        :placeholder="$t('location-placeholder')"
                                                        v-model="form.address"
                                                >
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-textarea
                                                    id="textarea"
                                                    v-model="form.detail"
                                                    :placeholder="$t('enter-something')"
                                                    rows="3"
                                                    max-rows="6"
                                            ></b-form-textarea>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6 mt-3">
                                            <b-button type="submit" variant="success" :disabled="isDisabled"
                                                      class="btn float-right btn-success">
                                                <span v-if="!this.form.id">{{ $t('submit-btn') }}</span>
                                                <span v-if="this.form.id">{{ $t('update-btn') }}</span>
                                            </b-button>
                                        </div>
                                    </div>
                                    <!--                                </form>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">

<div class="col-lg-12 stretch-card">
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">{{ $t('spotter-categories') }}</h4>
        </div>
        <div class="card-body new-card-body">
            <vue-good-table
                    mode="remote"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    :isLoading.sync="isLoading"
                    @on-search="searchFn"
                    :search-options="{enabled: true,searchFn: searchFn}"
                    :pagination-options="{
enabled: true,
dropdownAllowAll: false,
perPageDropdown: [10, 20, 50, 100, 200],
}"
                    :rows="rows"
                    :columns="columns">
                <template slot="table-row" slot-scope="props">
                    <span
                                    v-if="props.column.field === 'action'"
                                    class="text-nowrap"
                            >
                            <toggle-button
                            v-model="categories.find(p => p.id === props.row.id).show_on_spotter"
                            @change="changeStatus(props.row.id, $event)"
                            :sync="true"
                            class="mr-1 switchColor"/>
                                
                    </span>
                </template>
            </vue-good-table>
        </div>
    </div>
</div>
</div>
        </form>
    </section>
</template>

<script>
    import Vue from "vue";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, requiredIf, sameAs} from "vuelidate/lib/validators";
    import moment from 'moment';
    import i18n from '@/i18n';
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        name: 'Users',
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    id: '',
                    name: '',
                    user_name: '',
                    address: '',
                    mobile_phone: '',
                    date_completed: '',
                    password: '',
                    password_confirmation: '',
                    approval_date: '',
                    status: true,
                    auto_approve: true,
                    training_status: 1,
                    date: '',
                    current_spots: '',
                    email: '',
                    dob: '',
                    detail: '',
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    // show_on_spotter: null,
                },
                training_status: {
                    0: 'No',
                    1: 'Yes',
                },
                isDisabled: false,
                // readonly: true,
                showPassword: true,
                showPassword1: true,
                inputType: 'password',
                inputType1: 'password',
                checkedPassword: false,
                serverParams: {
                    user_id: 0 ,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [
                        {
                            field: 'id', // example: 'name'
                            type: 'desc' // 'asc' or 'desc'
                        }
                    ],

                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns: [
                    {
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Created At',
                        field: 'created_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Updated At',
                        field: 'updated_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                ],
                isLoading: false,
                rows: [],
                totalRecords: 0,
                categories: [],
                // show_on_spotter: null,
            };
        },
        validations: {
            form: {
                user_name: {
                    required,
                },
                name: {
                    required,
                },
                email: {
                    required, email
                },
                password: {
                    // required: requiredIf((value) => !value.id),
                    required:requiredIf(function(value){
                        if(this.form.id && this.checkedPassword){
                        return true; 
                        }
                        else if(!this.form.id){
                            return true;
                        }
                        // return !value.id
                        }),
                    minLength: minLength(6),
                    valid: function (value) {
                        if(this.form.id && this.checkedPassword){
                        const containsUppercase = /[A-Z]/.test(value)
                        const containsLowercase = /[a-z]/.test(value)
                        const containsNumber = /[0-9]/.test(value)
                        const containsSpecial = /[#?!@$%^&*-]/.test(value)
                        return containsUppercase && containsLowercase && containsNumber && containsSpecial
                        }
                        else{
                            return true;
                        }
                    },
                    valid2: function (value) {
                        if(!this.form.id){
                        const containsUppercase = /[A-Z]/.test(value)
                        const containsLowercase = /[a-z]/.test(value)
                        const containsNumber = /[0-9]/.test(value)
                        const containsSpecial = /[#?!@$%^&*-]/.test(value)
                        return containsUppercase && containsLowercase && containsNumber && containsSpecial
                        }
                        else{
                            return true;
                        }
                    },
                },
                password_confirmation: {
                    // required: requiredIf((value) => !value.id),
                    sameAsPassword: sameAs('password')
                }
            }    
        },
        mounted() {
            this.serverParams.user_id = this.$route.params.id;
            this.form.id = this.$route.params.id;
            if (this.form.id && this.form.id !== 'undefined') {
                this.readonly = true;
                this.getSpotter();
            } else {
                this.readonly = false;
            }
            this.loadItems();
        },
        methods: {
            onSubmit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }
                this.isDisabled = true;
                API.addUpdateSpotters(this.form,
                    data => {
                        this.isDisabled = false;
                        if (this.form.id) {
                            if (data.status === 200) {
                                this.$snotify.success(data.message, {
                                    timeout: 1000,
                                    showProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                }).on("destroyed", () => this.$router.push({name: 'spotters'}));
                                // this.$router.push({name: 'spotters'})
                            }
                            else {
                                this.$snotify.error(data.message, {
                                    timeout: 2000,
                                    showProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                        } else {
                            if (data.status === 410) {
                                this.$snotify.error(data.message, {
                                    timeout: 2000,
                                    showProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                });
                            }
                            else {
                                this.$snotify.success(data.message, {
                                    timeout: 1000,
                                    showProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                }).on("destroyed", () => this.$router.push({name: 'spotters'}));
                                // this.$router.push({name: 'spotters'})
                            }
                        }
                    },
                    err => {
                        this.isDisabled = false;
                        this.$snotify.error(err, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                )
            },
            getSpotter() {
                API.getSpotter(this.form.id,
                    data => {
                        console.log(data.data)
                        this.form.name = data.data.name;
                        this.form.user_name = data.data.user_name;
                        this.form.address = data.data.address;
                        this.form.email = data.data.users.email;
                        this.form.mobile_phone = data.data.mobile_phone;
                        this.form.detail = data.data.detail;
                        this.form.dob = data.data.dob;
                        this.form.date_completed = data.data.date_completed;
                        this.form.status = (data.data.status === 'A') ? true : false;
                        this.form.auto_approve = (data.data.auto_approve === 1) ? true : false;
                        this.form.training_status = data.data.training_status;
                    },
                    err => {
                    }
                )
            },
            addUpdateSpotters() {

            },
            toggleConfirmShow() {
                this.showPassword1 = !this.showPassword1;
                if (this.showPassword1) {
                    this.inputType1 = 'password';
                } else {
                    this.inputType1 = 'text';
                }
            },
            toggleShow() {
                this.showPassword = !this.showPassword;
                if (this.showPassword) {
                    this.inputType = 'password';
                } else {
                    this.inputType = 'text';
                }
            },
            changeStatus(id, event) {
                console.log(id, event.value);  
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    id: id,
                    user_id: this.form.id,
                    show_on_spotter: event.value === true ? 1 : 0 
                    
                };
                console.log(' CCid :',  id);
                API.updateSpotterCategoryStatus(
                    data,
                    data => {
                        this.loadItems();
                        if (data.status === 500) {
                            this.$snotify.success(data.message);
                        }
                    },
                    err => {}
                );
            },
            deleteCategory(e) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        // let data = {
                        //     id: e
                        // }
                        API.deleteCategory(
                            e,
                            data => {
                                this.loadItems();
                                this.$snotify.success('Record has been deleted.');
                            },
                            err => {
                            })
                    }
                })
            },
            editCategory(e) {
                this.$v.form.$reset();
                this.title = i18n.t('edit-category');
                this.$root.$emit("bv::show::modal", "modalmd");
                API.getEditCategory(
                    e, data => {
                        this.form.name = data.name;
                        this.form.id = data.id;
                        this.form.show_on_spotter = (data.data.show_on_spotter == 1) ? true : false;
                    },
                    err => {
                    }
                )
            },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({searchTerm: params});
                this.loadItems();
                this.isLoading = false;
            },
            formatStatus(status) {
            if (status === 1) {
                return true;
            } else {
                return false;
            }
        },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            loadItems() {
                this.isLoading = true;
                API.getAllSpotterCategories(this.serverParams,
                    data => {
                        this.isLoading = false;
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;
                        this.rows.forEach(q => {
                        const data = {
                            id: q.id,
                            show_on_spotter: q.show_on_spotter === 1 ? true : false
                            
                        };
                        this.categories.push(data);
                    });
                    },
                    err => {
                    }
                )
            },
        }, computed: {},

    }
</script>

<style>
    @media (max-width: 375px) {
        .row .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>
